import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneFooterSection";

class FooterTwo extends Component {
    state = {
        data: {},
        footerList_1: [],
        footerList_2: [],
        footerList_3: [],
        iconList: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    footerList_1: res.data.footerList_1,
                    footerList_2: res.data.footerList_2,
                    footerList_3: res.data.footerList_3,
                    iconList: res.data.iconList
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <footer className="section footer-area dark-bg">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                     <div className="container">
                              <div class="row">
                                 <div class="col-sm">
                                    <div class="footer-items">
                                       <h3 class="footer-title text-white text-uppercase mb-2">À Propos</h3>
                                       <ul>
                                          <li class="py-2"><a class="text-white-50" href="/team">Notre Équipe</a></li>
                                          <li class="py-2"><a class="text-white-50" href="/portfolio">Réalisations</a></li>
                                          <li class="py-2"><a class="text-white-50" href="/services">Nos Services Marketing</a></li>
                                          <li class="py-2"><a class="text-white-50" href="/consultation">Demande une consultation</a></li>
                                          <li class="py-2"><a class="text-white-50" href="/contact">Contactez-nous</a></li>
                                          <li class="py-2"><a class="text-white-50" href="/blog">Blog</a></li>
                                       </ul>
                                    </div>
                                 </div>
                                 <div class="col-sm">
                                    <div class="footer-items">
                                       <h3 class="footer-title text-white text-uppercase mb-2">Contact</h3>
                                       <ul>
                                          <li class="py-2"><a class="text-white-50" target="_blank"  href="https://www.google.com/maps/place/Digital+Spread/@35.7445362,-5.832366,15z/data=!4m5!3m4!1s0x0:0xb00f65058bd7f7cf!8m2!3d35.7445362!4d-5.832366"><i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;&nbsp;Residence El Azizia Bd Royaume Arabie Saoudite 3eme Etage N 20 - Tanger, Maroc </a></li>
                                          {/* <li class="py-2"><a class="text-white-50" target="_blank"  rel="noopener noreferrer" href="https://www.google.com/maps/place/Digital+Spread/@35.7445362,-5.832366,15z/data=!4m5!3m4!1s0x0:0xb00f65058bd7f7cf!8m2!3d35.7445362!4d-5.832366">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Etage N 20 - Tanger, Maroc</a></li> */}
                                          <li class="py-2"><a class="text-white-50" href="tel:+212531030318"><i  Style="font-size: 18px"  class="fab fa-whatsapp"></i>&nbsp;&nbsp;+212 6 40 20 46 38</a></li>
                                          <li class="py-2"><a class="text-white-50" href="tel:+212531030318"><i class="fas fa-phone-alt"></i>&nbsp;&nbsp;+212 5 31 03 03 18</a></li>
                                          <li class="py-2"><a class="text-white-50" href="mailto:contact@spread.ma"><i class="far fa-envelope"></i>&nbsp;&nbsp;&nbsp;Contact@spread.ma</a></li>
                                          <li class="py-2"><a class="text-white-50" href="mailto:Consultation@spread.ma"><i class="far fa-envelope"></i>&nbsp;&nbsp;&nbsp;Consultation@spread.ma</a></li>
                                       </ul>
                                    </div>
                                 </div>
                                 <div class="col-sm">
                                    <div class="footer-items">
                                       <a class="navbar-brand" href="/">
                                          <img class="navbar-brand-sticky" src="/img/logo-white1.png" alt="sticky brand-logo"/>
                                       </a>
                                       <p class="text-white-50 mb-2">Agence de Branding, Communication et Marketing Digital.</p>
                                       <ul class="social-icons list-inline pt-2">
                                          <li class="list-inline-item px-1">
                                             <a class="text-white-50" href="http://www.facebook.com/AgenceDigitalSpread">
                                                <i class="fab fa-facebook"></i>
                                             </a>
                                          </li>
                                          <li class="list-inline-item px-1">
                                             <a class="text-white-50" href="https://www.twitter.com/digitalspread1">

                                                <i class="fab fa-twitter"></i>
                                             </a>
                                          </li>

                                          <li class="list-inline-item px-1">
                                             <a class="text-white-50" href="https://www.linkedin.com/company/digitalspread">

                                                <i class="fab fa-linkedin-in"></i>
                                             </a>
                                          </li>
                                          <li class="list-inline-item px-1">
                                             <a class="text-white-50" href="http://www.behance.net/DigitalSpread">

                                                <i class="fab fa-behance"></i>
                                             </a>
                                          </li>
                                          <li class="list-inline-item px-1">
                                             <a class="text-white-50" href="http://www.instagram.com/digital.spread">

                                                <i class="fab fa-instagram"></i>
                                             </a>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                     </div>
                </div>
                {/* Footer Bottom */}


                     <div className="container">
                        <center>
                            <div className="justify-content-center">
                                {/* Copyright Area */}
                                <div className="d-flex flex-wrap justify-content-center text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="text-white-50">© 2021-2020 Digital Spread | Tous droits réservés.</div>
                                </div>
                            </div>
                        </center>
                    </div>

            </footer>
        );
    }
}

export default FooterTwo;