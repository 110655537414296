import React from 'react';

const Map = () => {
    return (
        <section className="section map-area">
                <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=N20%20Residence%20El%20Azizia%20Bd%20Royaume%20Arabie%20Saoudite,%20Tanger+(Digital%20Spread)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </section>
    );
};

export default Map;