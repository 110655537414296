import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://raw.githubusercontent.com/ykarouc/dammydata/main/data.json";

class PricingThree extends Component {
    state = {
        data: {},
        pricingData: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    pricingData: res.data.pricingData
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="pricing" className="section price-plan-area dark-bg overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-white">{this.state.data.heading}</h2>
                                <p className="text-white-50 d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="text-white-50 d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                            </div>
                        </div>
                    </div>





                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-8">
                            <div class="row price-plan-wrapper">
                                <div class="col-12 col-md-6">
                                    <div class="single-price-plan color-1 bg-hover hover-top dark-bg dark-shadow text-center p-5">
                                        <div class="plan-title mb-2 mb-sm-3">
                                            <h3 class="text-white mb-2">StartUP <sup><span class="badge badge-pill badge-warning ml-2"></span></sup></h3>
                                            <p class="text-white-50">Les Stratégies marketing conçues spécialement pour les startups, exigent une consultation</p>
                                            <br></br>
                                            <br></br>
                                        </div>
                                        <div class="plan-price pb-2 pb-sm-3"><span class="color-primary fw-7"></span><span class="h1 fw-7">Gratuitement</span><sub class="validity text-muted fw-5"></sub></div>
                                        <div class="plan-description">
                                            <ul class="plan-features">
                                                <li class="text-white py-2">Vous entrez en contact avec notre équipe d'experts</li>
                                                <li class="text-white py-2">5/7 jours par semaine</li>
                                                <li class="text-white py-2">Par telephone</li>
                                                <li class="text-white py-2">Email ou Skype</li>
                                            </ul>
                                        </div>
                                        <div class="plan-button"><a href="/consultation" class="btn btn-bordered-white mt-3">Consultation Gratuite</a></div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mt-4 mt-md-0">
                                    <div class="single-price-plan color-2 bg-hover active hover-top dark-bg dark-shadow text-center p-5">
                                        <div class="plan-title mb-2 mb-sm-3">
                                            <h3 class="text-white mb-2">Entreprise <sup><span class="badge badge-pill badge-warning ml-2">B2B ou B2C</span></sup></h3>
                                            <p class="text-white-50">Répondre à vos questions, définir des objectifs et mener des études préalables pour déterminer la stratégie appropriée pour votre affaire.</p>
                                        </div>
                                        <div class="plan-price pb-2 pb-sm-3"><span class="color-primary fw-7"></span><span class="h1 fw-7">Gratuitement</span><sub class="validity text-muted fw-5"></sub></div>
                                        <div class="plan-description">
                                            <ul class="plan-features">
                                                <li class="text-white py-2">Vous entrez en contact avec notre équipe d'experts</li>
                                                <li class="text-white py-2">5/7 jours par semaine</li>
                                                <li class="text-white py-2">Par telephone</li>
                                                <li class="text-white py-2">Email ou Skype</li>
                                            </ul>
                                        </div>
                                        <div class="plan-button"><a href="/consultation" class="btn btn-bordered-white active mt-3">Consultation Gratuite</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






















                    <div className="row justify-content-center pt-5">
                        <p className="pt-4 fw-5">{this.state.data.btnText} <a className="service-btn" href="/contact"><strong>{this.state.data.btnText_1}</strong></a></p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PricingThree;