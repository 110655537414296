import React, { Component } from 'react';

const initData = {
    heading: "L'agence de Branding, Communication et",
    heading_span: " Marketing Digital.",
    content: "Basée à Tanger (Maroc), Digital Spread est une agence de branding, communication et de marketing digital qui aide les entreprises marocaines à développer leur présence digitale et à attirer plus de clients en utilisant des stratégies de Marketing / Contenu. Nous sommes à vos côtés pour tous vos projets de communication, de votre identité visuelle à votre visibilité sur le Web!.",
    btnText_1: "Devis gratuits",
    btnText_2: "En savoir plus"
}

const data = [
    {
        id: 1,
        spanClass: "welcome-thumb-1",
        imgClass: "d-block ml-auto",
        image: "/img/welcome_thumb_11.png"
    },
    {
        id: 2,
        spanClass: "welcome-thumb-2",
        imgClass: "welcome-animation d-block",
        image: "/img/welcome_thumb_12.png"
    }
]

class HeroFive extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-7">
                            <div className="welcome-intro">
                                <h1 className="text-white"><span className="fw-4">{this.state.initData.heading}</span> {this.state.initData.heading_span}</h1>
                                <p className="text-white-50 my-4">{this.state.initData.content}</p>

                                {/* Buttons */}


                                <div class="button-group d-flex align-items-center justify-content-center justify-content-md-start ">
                                        <a href="/devis" className="btn btn-bordered-white active">{this.state.initData.btnText_1}</a>
                                        <a class="play-btn ml-3" data-fancybox="true" data-width="640" data-height="360" data-small-btn="true" href="https://youtu.be/x8FCzkXSh2U">
                                            <div class="btn-circle play-animation "></div>
                                            <div class="btn-circle play-animation animation-short "></div>
                                            <div class="play-icon">
                                                <svg class="svg-inline--fa fa-play fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                                    <path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path>
                                                </svg>
                                            </div>
                                        </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            {/* Welcome Thumb */}
                            <div className="welcome-thumb-wrapper mt-5 mt-md-0">
                                {this.state.data.map((item, idx) => {
                                    return(
                                        <span key={`hf_${idx}`} className={item.spanClass}>
                                            <img className={item.imgClass} src={item.image} alt="" />
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroFive;