import React, { Component } from 'react';

const initData = {
    title: "Menu",
    iconClass: "far fa-times-circle icon-close"
}

class ModalMenu extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <div id="menu" className="modal fade p-0">
                <div className="modal-dialog dialog-animated">
                    <div className="modal-content h-100">
                        <div className="modal-header" data-dismiss="modal">
                            {this.state.data.title} <i className={this.state.data.iconClass} />
                        </div>
                        <div className="menu modal-body">
                            <div className="row w-100">
                                <div className="items p-0 col-12 text-center" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalMenu;