import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOnePricingSection";

class PricingTwo extends Component {
    state = {
        data: {},
        pricingData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    pricingData: res.data.pricingData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="pricing" className="section price-plan-area overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <div className="row price-plan-wrapper">
                                {this.state.pricingData.map((item, idx) => {
                                    return(
                                        <div key={`pro_${idx}`} className={item.colClass}>
                                            {/* Single Price Plan */}
                                            <div className={item.linkClass}>
                                                {/* Plan Title */}
                                                <div className="plan-title mb-2 mb-sm-3">
                                                    <h3 className="mb-2">{item.title} <sup><span className="badge badge-pill badge-warning ml-2">{item.price_badge}</span></sup></h3>
                                                    <p>{item.text}</p>
                                                </div>
                                                {/* Plan Price */}
                                                <div className="plan-price pb-2 pb-sm-3">
                                                    <span className="color-primary fw-7">{item.price_before}</span>
                                                    <span className="h1 fw-7">{item.price}</span>
                                                    <sub className="validity text-muted fw-5">{item.price_after}</sub>
                                                </div>
                                                {/* Plan Description */}
                                                <div className="plan-description">
                                                    <ul className="plan-features">
                                                        <li className={item.listClass_1}>{item.li_1}</li>
                                                        <li className={item.listClass_1}>{item.li_2}</li>
                                                        <li className={item.listClass_2}>{item.li_3}</li>
                                                        <li className={item.listClass_2}>{item.li_4}</li>
                                                    </ul>
                                                </div>
                                                {/* Plan Button */}
                                                <div className="plan-button">
                                                    <a href="/#" className="btn btn-bordered mt-3">{item.btnText}</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-5">
                        <p className="pt-4 fw-5">{this.state.data.btnText} <a className="service-btn" href="/#"><strong>{this.state.data.btnText_1}</strong></a></p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PricingTwo;