import React, { Component } from 'react';

import BlogSingle from './BlogSingle';
import Pagination from './Pagination';

class Blogs extends Component {
    render() {
        return (
            <section id="blog" className="section blog-area ptb_100">
                <div className="container">
                    <BlogSingle />
                    <Pagination />
                </div>
            </section>
        );
    }
}

export default Blogs;