import React, { Component } from 'react';
import axios from 'axios';
import data from '../../data/contentFour.json'
//const BASE_URL = "https://raw.githubusercontent.com/cineebay/spread.ma/main/themeOneContentSection";

class ContentFour extends Component {
    state = {
        data: {},
        contentData: []
    }
    componentDidMount(){
        // axios.get(`${BASE_URL}`)
        //     .then(res => {
        //         this.setState({
        //             data: res.data,
        //             contentData: res.data.contentData
        //         })
        //         // console.log(this.state.data)
        //     })
        // .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section content-area dark-bg ptb_150">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            {/* Content Inner */}
                            <div className="content-inner text-center">
                                {/* Section Heading */}
                                <div className="section-heading text-center mb-3">
                                    <h2 className="text-white">{data.heading}</h2>
                                    <p className="text-white-50 d-none d-sm-block mt-4">{data.headingText_1}</p>
                                    <p className="text-white-50 d-block d-sm-none mt-4">{data.headingText_2}</p>
                                </div>
                                {/* Content List */}
                                <ul className="content-list text-left">
                                    {/* Single Content List */}
                                    {data.contentData.map((item, idx) => {
                                        return(
                                            <li key={`co_${idx}`} className="single-content-list media py-2">
                                                <div className="content-icon pr-4">
                                                    <span className="color-1"><i className={item.iconClass} /></span>
                                                </div>
                                                <div className="content-text media-body">
                                                    <span className="text-white"><b>{item.title}</b><br />{item.text}</span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <a href="/about" className="btn btn-bordered-white mt-4">{data.btnText}</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Service Thumb */}
                            <div className="service-thumb mx-auto pt-4 pt-lg-0">
                                <img src={data.contentThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContentFour;