import React, { Component } from 'react';

const initData = {
    heading: "Témoignages",
    headingText_1: "98% d’avis positifs. Découvrez ici les derniers avis et retours clients de notre agence Digital Spread",
    headingText_2: "98% d’avis positifs. Découvrez ici les derniers avis et retours clients de notre agence Digital Spread",
    btnText: "Travailler avec nous"
}

const data = [
    {
        id: 1,
        quote: "/img/quote.png",
        avatar: "/img/avatar_1.png",
        reviewer: "TARIK EL HADDANI",
        post: "TheBuzzSports.com",
        text: "“ Une équipe plein d’énergie, à l’écoute de ses clients… C’est d’abord des professionnels qui savent répondre à notre attente, et nous faire des propositions créatives. Un site internet est de plus en plus le reflet incontournable d’une entreprise, et trouver ceux qui seront garants de cette image n’est pas chose facile, compte tenu de la multiplicité des propositions.“"
    },
    {
        id: 2,
        quote: "/img/quote.png",
        avatar: "/img/avatar_2.png",
        reviewer: "ABDERRAZAK JOUSFI",
        post: "Corner.ma",
        text: "“ Ce que nous avons constaté chez notre partenaire Digital Spread : L’information était claire, les délais respectés, la rapidité d’intervention, l’excellent service fourni par un personnel très professionnel.“"
    }
]

class ReviewThree extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="review" className="section review-area bg-overlay ptb_100">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-4">
                            {/* Section Heading */}
                            <div className="section-heading m-0">
                                <h2 className="text-white">{this.state.initData.heading}</h2>
                                <p className="text-white d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className="text-white d-block d-sm-none mt-4">{this.state.initData.headingText_2}</p>
                                <a href="/contact" className="btn btn-bordered-white mt-4">{this.state.initData.btnText}</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">


                            <div class="row mt-5 mt-lg-0">
                                <div class="col-12 col-md-6 res-margin">
                                    <div class="single-review bg-inherit dark-shadow p-5">
                                        <div class="review-content">
                                            <div class="review-text">
                                            <p class="text-white-50">“ Une équipe plein d’énergie, à l’écoute de ses clients… C’est d’abord des professionnels qui savent répondre à notre attente, et nous faire des propositions créatives. Un site internet est de plus en plus le reflet incontournable d’une entreprise, et trouver ceux qui seront garants de cette image n’est pas chose facile, compte tenu de la multiplicité des propositions.“</p>
                                            </div>
                                            <div class="quot-icon"><img class="avatar-sm" src="/img/quote.png" alt="" /></div>
                                        </div>
                                        <div class="reviewer media mt-3">
                                            <div class="reviewer-thumb"><img class="avatar-lg radius-100" src="/img/avatar_1.png" alt="" /></div>
                                            <div class="reviewer-meta media-body align-self-center ml-4">
                                            <h5 class="reviewer-name text-white mb-2">TARIK EL HADDANI</h5>
                                            <h6 class="text-white-50 fw-6">TheBuzzSports.com</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 res-margin">
                                    <div class="single-review bg-inherit dark-shadow p-5">
                                        <div class="review-content">
                                            <div class="review-text">
                                            <p class="text-white-50">“ Ce que nous avons constaté chez notre partenaire Digital Spread : L’information était claire, les délais respectés, la rapidité d’intervention, l’excellent service fourni par un personnel très professionnel.“</p>
                                            </div>
                                            <div class="quot-icon"><img class="avatar-sm" src="/img/quote.png" alt="" /></div>
                                        </div><br></br><br></br><br></br><br></br><br></br>
                                        <div class="reviewer media mt-3">
                                            <div class="reviewer-thumb"><img class="avatar-lg radius-100" src="/img/avatar_2.png" alt="" /></div>
                                            <div class="reviewer-meta media-body align-self-center ml-4">
                                            <h5 class="reviewer-name text-white mb-2">ABDERRAZAK JOUSFI</h5>
                                            <h6 class="text-white-50 fw-6">Corner.ma</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewThree;