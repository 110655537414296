import React, { Component } from 'react';

import ScrollupSection from '../Scrollup/Scrollup';
import Header from '../Header/HeaderOne';
import BreadcrumbSection from '../Breadcrumb/BreadcrumbTwo';
import Blogs from './Blogs';
import FooterSection from '../Footer/FooterTwo';
import ModalSearchSection from '../Modal/ModalSearch';
import ModalMenuSection from '../Modal/ModalMenu';

class BlogTwoColumn extends Component {
    render() {
        return (
            <div className="blog">
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <BreadcrumbSection heading={"Blog Grid"} home={"Home"} page={"Blog Pages"} subpage={"Blog Grid"} title={"Grid 2 Column"} />
                    <Blogs />
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default BlogTwoColumn;