import React, { Component } from 'react';

const initData = {
    heading: "10 Ans d'expérience en Marketing Digital à votre service",
    headingText_1: "Nous entretenons des relations basées sur la transparence, la persévérance, la confiance mutuelle et l'intégrité avec nos employés, clients et autres partenaires commerciaux. Digital Spread équipe est composée des meilleurs spécialistes du marketing, designers et développeurs, et nous savons ce qu'il faut pour obtenir de vrais résultats en ligne. Nous nous concentrons également sur les paramètres qui comptent le plus, comme les prospects et les revenus générés. Nous savons que l'atteinte de ces objectifs est ce qui fait avancer les entreprises et nous croyons que le succès de nos clients est la meilleure mesure de notre propre performance.",
    headingText_2: "",
    image: "/img/about_thumb_1.jpg"
}

const data = [
    {
        id: 1,
        spanClass: "counter color-1 fw-7",
        spanClass_1: "color-1 fw-7",
        title: "375",
        text: "CLIENTS HEUREUX"
    },
    {
        id: 2,
        spanClass: "counter color-2 fw-7",
        spanClass_1: "color-2 fw-7",
        title: "1500",
        text: "PROJETS RÉALISÉS"
    },
    {
        id: 3,
        spanClass: "counter color-3 fw-7",
        spanClass_1: "color-3 fw-7",
        title: "10",
        text: "Ans d'EXPÉRIENCES"
    }
]

class AboutUs extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section about-area ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* About Thumb */}
                            <div className="about-thumb text-center">
                                <img src={this.state.initData.image} alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* About Content */}
                            <div className="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                                <h2 className="mb-3">{this.state.initData.heading}</h2>
                                <p>{this.state.initData.headingText_1}</p><br />
                                <p>{this.state.initData.headingText_2}</p>
                                {/* Counter Area */}
                                <div className="couter-area mt-5">
                                    {/* Single Counter */}
                                    {this.state.data.map((item, idx) => {
                                        return(
                                            <div className="single-counter d-block d-md-inline-block m-4 m-md-2">
                                                <div className="counter-inner">
                                                    {/* Counter Item */}
                                                    <div className="counter-item d-inline-block mb-3">
                                                        <span className={item.spanClass}>{item.title}</span><span className={item.spanClass_1}>+</span>
                                                    </div>
                                                </div>
                                                <h4 className="text-uppercase fw-7">{item.text}</h4>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutUs;