import React, { Component } from 'react';

import ScrollupSection from '../../Scrollup/Scrollup';
import Header from '../../Header/HeaderOne';
import BreadcrumbSection from '../../Breadcrumb/BreadcrumbTwo';
import PricingSection from '../../Pricing/PricingFour';
import ReviewSection from '../../Reviews/ReviewOne';
import ContactSection2 from '../../Contact/Consultation';
import CtaSection from '../../Cta/Cta';
import FooterSection from '../../Footer/FooterTwo';
import ModalSearchSection from '../../Modal/ModalSearch';
import ModalMenuSection from '../../Modal/ModalMenu';

class Pricing extends Component {
    render() {
        return (
            <div>
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <BreadcrumbSection heading={"CONSULTATION"} title={"Notre équipe est prête à fournir une consultation complète avec des recommandations gratuitement. "} />
                    <ContactSection2 />
                    <CtaSection />
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default Pricing;