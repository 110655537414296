import React from 'react';

const Pagination = () => {
    return (
        <div className="row">
            <div className="col-12">
                {/* Pagination */}
                <ul className="pagination justify-content-center">
                    <li className="disabled px-1">
                        <a href="/#" aria-label="Previous">
                            <i className="fas fa-arrow-left" />
                        </a>
                    </li>
                    <li className="px-1"><a href="/#">1</a></li>
                    <li className="active px-1"><a href="/#">2</a></li>
                    <li className="px-1"><a href="/#">3</a></li>
                    <li>
                        <a href="/#" aria-label="Next">
                            <i className="fas fa-arrow-right" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;