import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://raw.githubusercontent.com/cineebay/spread.ma/main/themeOneContactSection";

class ContactOne extends Component {
    state = {
        data: {},
        contactData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    contactData: res.data.contactData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="contact" className="contact-area ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-5">
                            {/* Section Heading */}
                            <div className="section-heading text-center mb-3">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">Demandez une consultation gratuite en remplissant le formulaire.</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                            </div>
                            {/* Contact Us */}
                            <div className="contact-us">
                                <ul>
                                    {/* Contact Info */}
                                    {this.state.contactData.map((item, idx) => {
                                        return(
                                            <li key={`co_${idx}`} className={item.listClass}>
                                                <span><i className={item.iconClass} /></span>
                                                <a className={item.linkClass} href={item.hreflink}>
                                                    <h3>{item.title}</h3>
                                                </a>
                                                <p>{item.text}</p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-4 pt-lg-0">
                            {/* Contact Box */}
                            <div className="contact-box text-center">
                                {/* Contact Form */}
                                <form id="contact-form" method="POST" action="/php/mail.php">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="name" placeholder="Nom" required="required" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="name" placeholder="Nom de l'entreprise" required="required" />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" placeholder="Email" required="required" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="subject" placeholder="téléphone" required="required" />
                                            </div>
                                            {/* <div className="form-group">
                                                    <label class="radio-inline">
                                                    <input type="radio" name="optradio" checked/>un particulier
                                                    </label>
                                                    <label class="radio-inline">
                                                    <input type="radio" name="optradio"/>une entreprise
                                                    </label>
                                                    <label class="radio-inline">
                                                    <input type="radio" name="optradio"/>une start-up
                                                    </label>
                                                    <label class="radio-inline">
                                                    <input type="radio" name="optradio"/>autre
                                                    </label>
                                                    
                                            </div> */}

                                            <div class="col-12">
                                                <label class="float-left">Êtes-vous    :</label>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline1" value="Particulier" name="customRadioInline" class="custom-control-input"/>
                                                    <label class="custom-control-label" for="customRadioInline1">Particulier</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline2" value="Entreprise" name="customRadioInline" class="custom-control-input"/>
                                                    <label class="custom-control-label" for="customRadioInline2">Entreprise</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline3" value="Start-up" name="customRadioInline" class="custom-control-input"/>
                                                    <label class="custom-control-label" for="customRadioInline3">Start-up</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline4" value="Autre" name="customRadioInline" class="custom-control-input"/>
                                                    <label class="custom-control-label" for="customRadioInline4">Autre</label>
                                                </div>
                                            </div>                                          


                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control" name="message" placeholder="Message" required="required" defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-bordered active btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>Envoyer ma Demande</button>
                                        </div>
                                    </div>
                                </form>
                                <p className="form-message" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactOne;