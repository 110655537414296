import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneSidebarData";

class Sidebar extends Component {
    state = {
        data: {},
        widgetData_1: [],
        widgetData_2: [],
        widgetData_3: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    widgetData_1: res.data.widgetData_1,
                    widgetData_2: res.data.widgetData_2,
                    widgetData_3: res.data.widgetData_3
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <aside className="sidebar mb-5 mb-lg-0">
                {/* Single Widget */}
                <div className="single-widget">
                    {/* Search Widget */}
                    <div className="widget-content search-widget">
                        <form action="#">
                            <input type="text" placeholder="Enter your keywords" />
                        </form>
                    </div>
                </div>
                {/* Single Widget */}
                <div className="single-widget">
                    {/* Category Widget */}
                    <div className="widget catagory-widget">
                        <h5 className="text-uppercase d-block py-3">{this.state.data.widgetTitle_1}</h5>
                        {/* Category Widget Content */}
                        <div className="widget-content">
                            {/* Category Widget Items */}
                            <ul className="widget-items">
                                {this.state.widgetData_1.map((item, idx) => {
                                    return(
                                        <li key={`wdo_${idx}`}>
                                            <a href="/#" className="d-flex py-3"><span>{item.title}</span><span className="ml-auto">{item.content}</span></a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Single Widget */}
                <div className="single-widget">
                    {/* Post Widget */}
                    <div className="widget post-widget">
                        <h5 className="text-uppercase d-block py-3">{this.state.data.widgetTitle_2}</h5>
                        {/* Post Widget Content */}
                        <div className="widget-content">
                            {/* Post Widget Items */}
                            <ul className="widget-items">
                                {this.state.widgetData_2.map((item, idx) => {
                                    return(
                                        <li key={`wdt_${idx}`}>
                                            <a href="/#" className="single-post media py-3">
                                                {/* Post Thumb */}
                                                <div className="post-thumb avatar-lg h-100">
                                                    <img className="align-self-center" src={item.image} alt="" />
                                                </div>
                                                <div className="post-content media-body ml-3 py-2">
                                                    <p className="post-date mb-2">{item.date}</p>
                                                    <h6>{item.title}</h6>
                                                </div>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Single Widget */}
                <div className="single-widget">
                    {/* Tags Widget */}
                    <div className="widget tags-widget">
                        <h5 className="text-uppercase d-block py-3">{this.state.data.widgetTitle_3}</h5>
                        {/* Tags Widget Content */}
                        <div className="widget-content">
                            {/* Tags Widget Items */}
                            <div className="widget-content tags-widget-items pt-2">
                                {this.state.widgetData_3.map((item, idx) => {
                                    return(
                                        <a key={`wdth_${idx}`} href="/#" className="d-inline-block mt-2 mr-1 px-2 py-1">{item.text}</a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        );
    }
}

export default Sidebar;