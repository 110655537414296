import React, { Component } from 'react';

const initData = {
    heading: "Comment nous travaillons?",
    headingText_1: "Contrairement à de nombreuses agences de marketing, chez Digital Spread, nous pensons que le meilleur travail provient d'une étroite collaboration entre le client et le chef de projet - et entre le chef de projet et l'équipe de production. Chacun de nos chefs de projet possède une vaste expérience dans le Marketing Digital, Email marketing, SEO ou la rédaction de contenu personnalisé, ce qui nous permet d'atteindre très facilement les objectifs de nos clients.",
    headingText_2: "Votre chef de projet reste impliqué tout au long du processus, affectant un concepteur et un rédacteur dont les forces correspondent à vos objectifs, et travaillant directement avec eux pour s'assurer que le produit final capture avec précision ce qui distingue votre entreprise, a l'impact que vous voulez qu'il ait. , et enfin, dépasse vos attentes.",
    image: "/img/about_thumb_2.jpg",
    btnText: ""
}

class Goal extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="section our-goal ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* Goal Content */}
                            <div className="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                                <h2 className="mb-3">{this.state.data.heading}</h2>
                                <p>{this.state.data.headingText_1}</p><br />
                                <p>{this.state.data.headingText_2}</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Goal Thumb */}
                            <div className="goal-thumb mt-5 mt-lg-0">
                                <img src={this.state.data.image} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Goal;