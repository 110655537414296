import React, { Component } from 'react';

import ScrollupSection from '../components/Scrollup/Scrollup';
import Header from '../components/Header/HeaderOne';
import HeroSection from '../components/Hero/HeroFour';
import BrandingSection from '../components/Branding/Branding';
import ContentSection from '../components/Content/ContentOne';
import ContentSectionTwo from '../components/Content/ContentTwo';
import ServiceSection from '../components/Services/ServiceFour';
import PricingSection from '../components/Pricing/PricingTwo';
import ReviewSection from '../components/Reviews/ReviewOne';
import ContactSection from '../components/Contact/ContactOne';
import CtaSection from '../components/Cta/Cta';
import FooterSection from '../components/Footer/FooterOne';
import ModalSearchSection from '../components/Modal/ModalSearch';
import ModalMenuSection from '../components/Modal/ModalMenu';

class ThemeFour extends Component {
    render() {
        return (
            <div className="homepage-4">
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <HeroSection />
                    <BrandingSection />
                    <ContentSection />
                    <ContentSectionTwo />
                    <ServiceSection />
                    <PricingSection />
                    <ReviewSection />
                    <ContactSection />
                    <CtaSection />
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default ThemeFour;