import React, { Component } from 'react';

const initData = {
    btnText: "View More"
}

const data = [
    {
        id: 1,
        image: "/img/blog_1.jpg",
        title: "Digital Marketing",
        text: "Nous avons aidé des dizaines de clients à promouvoir leurs entreprises grâce à des stratégies de marketing personnalisées qui correspondent à leurs besoins. "
    },
    {
        id: 2,
        image: "/img/blog_2.jpg",
        title: "Branding",
        text: "Nous avons contribué au branding des nombreuses startups en créant un nom, un symbole et un design facilement identifiable."
    },
    {
        id: 3,
        image: "/img/blog_3.jpg",
        title: "Présence sur les Réseaux Sociaux",
        text: "La présence en ligne est très importante dans le monde digital d'aujourd'hui, c'est pourquoi nous nous considérons également comme des experts après avoir placé un bon nombre de nos entreprises clientes (clientèles) sur la carte des médias sociaux afin qu'elles puissent atteindre plus de clients."
    },
    {
        id: 4,
        image: "/img/blog_4.jpg",
        title: "Capsules D’animation Motion Design",
        text: "Pour Transmettre des messages clairs de manière originale. Notre agence a créé des capsules de motion design qui donnent à nos clients le charme et la magie des institutions de marque."
    },
    {
        id: 5,
        image: "/img/blog_5.jpg",
        title: "Content Management & Communication Strategies",
        text: "Nous avons créé des contenus qui ont donné une réelle valeur à nos partenaires et nous les avons aidés avec les stratégies de communication qui les ont rapprochés de leurs clients."
    },
    {
        id: 6,
        image: "/img/blog_6.jpg",
        title: "Développement Web / App",
        text: "Nous avons aidé plusieurs entreprises à passer au monde digital via des sites Web et des applications, et nous avons également transformé les idées de nos clients en projets digital qui répondent à leurs besoins."
    },
    {
        id: 7,
        image: "/img/blog_7.jpg",
        title: "Graphic Design",
        text: "La Graphic design n'est pas seulement du marketing, mais elle fait vraiment partie de la stratégie marketing. la clé est que le designer comprenne l'essence de votre marque et vos objectifs marketing - c'est ce que nous avons fait avec nos clients pour créer ces connexions émotionnelles et ces expériences."
    },
    {
        id: 8,
        image: "/img/blog_8.jpg",
        title: "SEO / Optimisation du Moteur de Recherche.",
        text: "Nous avons apporté un trafic qualifié sur les sites Web de nos clients ; nous appliquons les meilleures stratégies de SEO en tenant compte du budget client et de l'objectif final !"
    },
    {
        id: 9,
        image: "/img/blog_9.jpg",
        title: "Services Digital Personnalisables",
        text: "Nous avons réalisé de nombreux projets avec succès pour nos clients qui ont été personnalisés en fonction de leurs besoins, tels que la mise en place des magasins sur les différentes plates-formes CMS ou lancer des campagnes Push Ads et Native Ads."
    }
]

class PortfolioFive extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="portfolio" className="portfolio-area style-two bg-gray overflow-hidden ptb_100">
                <div className="row justify-content-center">
                    {this.state.data.map((item, idx) => {
                        return(
                            <div key={`pf_${idx}`} className="col-12 col-sm-6 col-lg-4 p-0">
                                {/* Single Case Studies */}
                                <div className="single-case-studies mb-0">
                                    {/* Case Studies Thumb */}
                                    <a href="/#">
                                        <img src={item.image} alt="" />
                                    </a>
                                    {/* Case Studies Overlay */}
                                    <a href="/#" className="case-studies-overlay">
                                        {/* Overlay Text */}
                                        <span className="overlay-text text-center p-3">
                                            <h3 className="text-white mb-3">{item.title}</h3>
                                            <p className="text-white">{item.text}</p>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
>
            </section>
        );
    }
}

export default PortfolioFive;