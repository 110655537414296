import React, { Component } from 'react';

import ScrollupSection from '../Scrollup/Scrollup';
import Header from '../Header/HeaderOne';
import BreadcrumbSection from '../Breadcrumb/BreadcrumbTwo';
import BlogSingle from './BlogSingle';
import Sidebar from './Sidebar';
import Pagination from './Pagination';
import FooterSection from '../Footer/FooterThree';
import ModalSearchSection from '../Modal/ModalSearch';
import ModalMenuSection from '../Modal/ModalMenu';

class BlogLeftSidebar extends Component {
    render() {
        return (
            <div className="blog">
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <BreadcrumbSection heading={"Blog Grid"} home={"Home"} page={"Blog Pages"} subpage={"Blog Grid"} title={"Left Sidebar"} />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-3">
                                    <Sidebar />
                                </div>
                                <div className="col-12 col-lg-9">
                                    <BlogSingle />
                                    <Pagination />
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default BlogLeftSidebar;