import React, { Component } from 'react';

import ScrollupSection from '../../../Scrollup/Scrollup';
import Header from '../../../Header/HeaderOne';
import BreadcrumbSection from '../../../Breadcrumb/BreadcrumbOne';
import PortfolioSection from '../../../Portfolio/PortfolioFive';
import ReviewSection from '../../../Reviews/ReviewOne';
import ContactSection from '../../../Contact/ContactOne';
import CtaSection from '../../../Cta/Cta';
import FooterSection from '../../../Footer/FooterTwo';
import ModalSearchSection from '../../../Modal/ModalSearch';
import ModalMenuSection from '../../../Modal/ModalMenu';

class PortfolioNoGap extends Component {
    render() {
        return (
            <div>
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <BreadcrumbSection heading={"Réalisations"} home={"Accueil"} page={"Pages"} subpage={"Réalisations"} title={"Tout"} />
                    <PortfolioSection />
                    <ReviewSection />
                    <ContactSection />
                    <CtaSection />
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default PortfolioNoGap;