import React, { Component } from 'react';

class HeaderOne extends Component {
    render() {
        return (
            <header id="header">
                {/* Navbar */}
                <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                    <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-regular" src={this.props.imageData} alt="brand-logo" />
                        <img className="navbar-brand-sticky" src="/img/logo.png" alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                        {/* Navbar */}
                        <ul className="navbar-nav items">
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/">L'agence<i className="" /></a>

                            </li>
                            <li className="nav-item">
                                <a href="/about" className="nav-link">A propos</a>
                            </li>
                            <li className="nav-item "> 
                                <a href="/services" className="nav-link">Services</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/#">Pages <i className="fas fa-angle-down ml-1" /></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a href="/portfolio" className="nav-link">Réalisations</a></li>
                                    <li className="nav-item"><a href="/team" className="nav-link">Notre Équipe</a></li>
                                    <li className="nav-item"><a href="/contact" className="nav-link">Contactez-Nous</a></li>
                                </ul>
                            </li>

                        </ul>
                        {/* Navbar Icons */}
                        <ul className="navbar-nav icons">
                            <li className="nav-item">
                                <a href="http://www.behance.net/DigitalSpread" className="nav-link"  >
                                    <i className="fab fa-behance" />
                                </a>
                            </li>
                            <li className="nav-item social">
                                <a href="http://www.facebook.com/AgenceDigitalSpread" className="nav-link"><i className="fab fa-facebook-f" /></a>
                            </li>
                            <li className="nav-item social">
                                <a href="https://www.linkedin.com/company/digitalspread" className="nav-link"><i className="fab fa-linkedin" /></a>
                            </li>
                        </ul>
                        {/* Navbar Toggler */}
                        <ul className="navbar-nav toggle">
                            <li className="nav-item">
                                <a href="/#" className="nav-link" data-toggle="modal" data-target="#menu">
                                    <i className="fas fa-bars toggle-icon m-0" />
                                </a>
                            </li>
                        </ul>
                        {/* Navbar Action Button */}
                        <ul className="navbar-nav action">
                            <li className="nav-item ml-3">
                                <a href="/contact" className="btn ml-lg-auto btn-bordered-white"><i className="fas fa-paper-plane contact-icon mr-md-2" />CONTACTEZ-NOUS</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default HeaderOne;