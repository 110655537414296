import React, { Component } from 'react';

const initData = {
    heading: "We are working with",
    headingText_1: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const data = [
    {
        id: 1,
        image: "/img/brand_partner_1.png"
    },
    {
        id: 2,
        image: "/img/brand_partner_2.png"
    },
    {
        id: 3,
        image: "/img/brand_partner_3.png"
    },
    {
        id: 4,
        image: "/img/brand_partner_4.png"
    },
    {
        id: 5,
        image: "/img/brand_partner_5.png"
    }
]

class Branding extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section brand-partner ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center mb-2">
                                <h2>{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.initData.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* Brand Partner Wrapper */}
                            <ul className="brand-partner-wrapper list-inline text-center">
                                {/* Single Partner */}
                                {this.state.data.map((item, idx) => {
                                    return(
                                        <li key={`b_${idx}`} className="single-partner list-inline-item p-lg-3">
                                            <img src={item.image} alt="" />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Branding;