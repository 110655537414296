import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json/themeOnePromoSection";

class PromoTwo extends Component {
    state = {
        promoData_1: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    promoData_1: res.data.promoData_1
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section promo-area ptb_100">
                <div className="container">
                    <div className="row">
                        {this.state.promoData_1.map((item, idx) => {
                            return(
                                <div key={`prt_${idx}`} className="col-12 col-md-6 col-lg-4 res-margin">
                                    {/* Single Promo */}
                                    <div className={item.listClass}>
                                        {/* Promo Wrapper */}
                                        <div className="promo-wrapper">
                                            <h3 className="mb-3">{item.title}</h3>
                                            <p>{item.text_1} <strong>{item.text_span}</strong> {item.text_2}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default PromoTwo;