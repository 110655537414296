import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json/themeOnePromoSection";

class PromoOne extends Component {
    state = {
        promoData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    promoData: res.data.promoData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section promo-area ptb_100">
                <div className="container">
                    <div className="row">
                        {this.state.promoData.map((item, idx) => {
                            return(
                                <div key={`pro_${idx}`} className="col-12 col-md-6 col-lg-4 res-margin">
                                    {/* Single Promo */}
                                    <div className={item.linkClass}>
                                        <h3 className="mb-3">{item.title}</h3>
                                        <p>{item.text_1} <strong>{item.text_span}</strong> {item.text_2}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default PromoOne;