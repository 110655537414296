import React, { Component } from 'react';

import ScrollupSection from '../components/Scrollup/Scrollup';
import Header from '../components/Header/HeaderOne';
import HeroSection from '../components/Hero/HeroFive';
import ServiceSection from '../components/Services/ServiceFive';
import ContentSection from '../components/Content/ContentFour';
import ContentSectionTwo from '../components/Content/ContentFive';
import PricingSection from '../components/Pricing/PricingThree';
import ReviewSection from '../components/Reviews/ReviewThree';
import ContactSection from '../components/Contact/ContactTwo';
import CtaSection from '../components/Cta/Cta';
import FooterSection from '../components/Footer/FooterTwo';
import ModalSearchSection from '../components/Modal/ModalSearch';
import ModalMenuSection from '../components/Modal/ModalMenu';

class ThemeFive extends Component {
    render() {
        return (
            <div className="homepage-5 dark">
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <HeroSection />
                    <ServiceSection />
                    <ContentSection />
                    <ContentSectionTwo />
                    <PricingSection />
                    <ReviewSection />
                    <ContactSection />
                    <CtaSection />
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default ThemeFive;