import React, { Component } from 'react';

const initData = {
    heading: "Marketing",
    heading_span: "strategy",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.",
    btnText: "Start a Project",
    iconClass: "fas fa-play",
    image: "/img/hero_shape.png"
}

const data = [
    {
        id: 1,
        spanClass: "welcome-thumb-1",
        imgClass: "welcome-animation d-block ml-auto",
        image: "/img/welcome_thumb_7.png"
    },
    {
        id: 2,
        spanClass: "welcome-thumb-2",
        imgClass: "welcome-animation d-block",
        image: "/img/welcome_thumb_8.png"
    },
    {
        id: 3,
        spanClass: "welcome-thumb-3",
        imgClass: "welcome-animation d-block",
        image: "/img/welcome_thumb_9.png"
    },
    {
        id: 4,
        spanClass: "welcome-thumb-4",
        imgClass: "welcome-animation d-block",
        image: "/img/welcome_thumb_10.png"
    }
]

class HeroThree extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-6">
                            <div className="welcome-intro">
                            <h1 className="text-white text-center text-md-left">{this.state.initData.heading} <br /> <span className="fw-4">{this.state.initData.heading_span}</span></h1>
                            <p className="text-white text-center text-md-left my-4">{this.state.initData.content}</p>
                            {/* Buttons */}
                            <div className="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                                <a href="/#" className="btn btn-bordered-white">{this.state.initData.btnText}</a>
                                {/* Play Button */}
                                <a className="play-btn" data-fancybox data-width={640} data-height={360} data-small-btn="true" href="https://youtu.be/TKnufs85hXk">
                                    <div className="btn-circle play-animation" />
                                    <div className="btn-circle play-animation animation-short" />
                                    {/* Play Icon */}
                                    <div className="play-icon">
                                        <i className={this.state.initData.iconClass} />
                                    </div>
                                </a>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            {/* Welcome Thumb */}
                            <div className="welcome-thumb-wrapper pt-3 pt-md-5 mt-5">
                                {this.state.data.map((item, idx) => {
                                    return(
                                        <span key={`hth_${idx}`} className={item.spanClass}>
                                            <img className={item.imgClass} src={item.image} alt="" />
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="welcome-shape">
                    <img src={this.state.initData.image} alt="" />
                </div>
            </section>
        );
    }
}

export default HeroThree;