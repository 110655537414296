import React, { Component } from 'react';

const initData = {
    heading: "Ça vous intéresse de nous confier un projet?",
    headingText_1: "Nous avons rassemblé les esprits les plus brillants et les plus innovants issus de vastes domains et les avons réunis sous un même toit. Cette combinaison d'individus sélectionnés est la sauce secrète derrière Spread.ma.	",
    headingText_2: "Nous avons rassemblé les esprits les plus brillants et les plus innovants issus de vastes domains et les avons réunis sous un même toit. Cette combinaison d'individus sélectionnés est la sauce secrète derrière Spread.ma.	",
    btnText: "CONTACTEZ-NOUS"
}

class Cta extends Component {
    state = {
        data: {}
    }
    componentDidMount() {
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="section cta-area bg-overlay ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            {/* Section Heading */}
                            <div className="section-heading text-center m-0">
                                <h2 className="text-white">{this.state.data.heading}</h2>
                                <p className="text-white d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="text-white d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                                <div class="content-inner text-center">
                                    <a href="/contact" class="btn btn-bordered-white mt-4">CONTACTER-NOUS</a>
                                </div>



                                

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        );
    }
}

export default Cta;