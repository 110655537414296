import React, { Component } from 'react';

const initData = {
    heading: "Pourquoi faire confiance à Digital Spread pour vos projet?",
    headingText_1: "98% d’avis positifs. Découvrez ici les derniers avis et retours clients de notre agence.",
    headingText_2: "98% d’avis positifs. Découvrez ici les derniers avis et retours clients de notre agence.",
}

const data = [
    {
        id: 1,
        quote: "/img/quote.png",
        avatar: "/img/avatar_1.png",
        reviewer: "FATIMA ZAHRA ABBARI",
        post: "Be one consulting",
        text: "“Digital Spread est notre agence de communication officielle depuis 2011. Elle l’est toujours d’ailleurs pour les raisons suivantes : Une forte capacité de ses fondateurs à tisser des relations de confiance, compréhension du besoin du client et accompagnement personnalisé.“"
    },
    {
        id: 2,
        quote: "/img/quote.png",
        avatar: "/img/avatar_2.png",
        reviewer: "ABDERRAZAK JOUSFI",
        post: "Corner.ma",
        text: "“ Ce que nous avons constaté chez notre partenaire Digital Spread : L’information était claire, les délais respectés, la rapidité d’intervention, l’excellent service fourni par un personnel très professionnel.“"
    },
    {
        id: 3,
        quote: "/img/quote.png",
        avatar: "/img/avatar_3.png",
        reviewer: "ZOUBIDA BOUTAYEB",
        post: "Aob consulting",
        text: "“ Digital Spread nous a semblé être efficace et dynamique, ce qui s’est confirmé tout au long de notre collaboration. L’agence a toujours été à l’écoute de nos besoins pour nous faire les meilleurs proposition en terme de supports de communication écrits et électroniques de nos différents produits. Il faut avouer aussi que toutes nos demandes ont toujours été remises en un temps record ce qui nous permettaient à chaque fois de bien avancer dans nos projets. Digital Spread est réellement un partenaire de confiance qui fait le maximum pour ne pas décevoir son client.“"
    }
]

class ReviewOne extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="review" className="section review-area bg-overlay ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-white">{this.state.initData.heading}</h2>
                                <p className="text-white d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className="text-white d-block d-sm-none mt-4">{this.state.initData.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Client Reviews */}
                        <div className="client-reviews owl-carousel">
                            {/* Single Review */}
                            {this.state.data.map((item, idx) => {
                                return(
                                    <div key={`ro_${idx}`} className="single-review p-5">
                                        {/* Review Content */}
                                        <div className="review-content">
                                            {/* Review Text */}
                                            <div className="review-text">
                                                <p>{item.text}</p>
                                            </div>
                                            {/* Quotation Icon */}
                                            <div className="quot-icon">
                                                <img className="avatar-sm" src={item.quote} alt="" />
                                            </div>
                                        </div>
                                        {/* Reviewer */}
                                        <div className="reviewer media mt-3">
                                            {/* Reviewer Thumb */}
                                            <div className="reviewer-thumb">
                                                <img className="avatar-lg radius-100" src={item.avatar} alt="" />
                                            </div>
                                            {/* Reviewer Media */}
                                            <div className="reviewer-meta media-body align-self-center ml-4">
                                                <h5 className="reviewer-name color-primary mb-2">{item.reviewer}</h5>
                                                <h6 className="text-secondary fw-6">{item.post}</h6>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewOne;