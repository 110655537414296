import React, { Component } from 'react';
import axios from 'axios';
import data from '../../data/data.json';

// const BASE_URL = "https://raw.githubusercontent.com/cineebay/spread.ma/main/db.json";

class ServiceOne extends Component {
    state = {
        data: {},
        serviceData: []
    }
    componentDidMount(){
        // console.log(data)
        // axios.get(`${BASE_URL}`)
        //     .then(res => {
        //         this.setState({
        //             data: res.data,
        //             serviceData: res.data.serviceData
        //         })
        //         // console.log(this.state.data)
        //     })
        // .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section service-area dark-bg ptb_150">
                <div className="container">
                    <div  id="service"  className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-white">{data.heading}</h2>
                                <p className="text-white-50 d-none d-sm-block mt-4">{data.headingText_1}</p>
                                <p className="text-white-50 d-block d-sm-none mt-4">{data.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {data.serviceData.map((item, idx) => {
                            return(
                                <div key={`so_${idx}`} className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service media p-3">
                                        <span className={item.iconClass} />
                                        {/* Service Text */}
                                        <div className="media-body ml-4">
                                            <h3 className="text-white">{item.title}</h3>
                                            <p className="text-white-50 mt-2 mb-3">{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceOne;