import React, { Component } from 'react';

import BlogSingleTwo from './BlogSingleTwo';
import Pagination from './Pagination';

class BlogsTwo extends Component {
    render() {
        return (
            <section id="blog" className="section blog-area ptb_100">
                <div className="container">
                    <BlogSingleTwo />
                    <Pagination />
                </div>
            </section>
        );
    }
}

export default BlogsTwo;